import React, { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import WatchForOutsideClick from "components/WatchForOutsideClick";
import colors from "colors";
const NO_SELECTION = -1;

const menuItemHeight = "1.5 rem";
const iconContainerSize = "2rem";
const iconSize = "1.5rem";

const PopoverMenu = (props) => {
  const anchorEl = React.createRef();
  const scrollEl = React.createRef();
  const containerEl = React.createRef();
  const arrowEl = React.createRef();
  const [selectedItem, setSelectedItem] = useState(NO_SELECTION);
  const [windowSize, setWindowSize] = useState({
    innerHeight: 0,
    innerWidth: 0,
  });

  const assignLayout = () => {
    if (anchorEl.current && containerEl.current) {
      let layout = {
        percentVisible: 1,
        top: `${
          anchorEl.current.offsetTop +
          anchorEl.current.offsetHeight +
          props.scrollTop +
          3
        }px`,
        left: `${
          anchorEl.current.offsetLeft - containerEl.current.offsetWidth / 2
        }px`,
      };
      layout = { ...layout, ...props.layout };
      containerEl.current.style.position = "fixed";
      containerEl.current.style.top = layout.top;
      containerEl.current.style.left = layout.left;
      if (props.resetToTop) scrollEl.current.scrollTop = 0;
    }
  };

  useLayoutEffect(() => {
    const onResize = () => {
      if (
        window.innerHeight !== windowSize.innerHeight ||
        window.innerWidth !== windowSize.innerWidth
      ) {
        assignLayout();
        if (containerEl.current) containerEl.current.style.display = "none";
        setWindowSize({
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
        });
      }
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  useEffect(() => {
    assignLayout();
    if (containerEl.current) containerEl.current.style.display = "none";
  }, [props.scrollTop]);

  return (
    <WatchForOutsideClick
      onOutsideClick={() => {
        if (containerEl.current) containerEl.current.style.display = "none";
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          containerEl.current.style.display =
            containerEl.current.style.display === "none" ? "flex" : "none";
          assignLayout();
        }}
      >
        {(props.children && (
          <div
            style={{
              position: "relative",
            }}
            ref={anchorEl}
          >
            {props.children}
          </div>
        )) || (
          <div ref={anchorEl} style={styles.button}>
            <div
              style={{
                position: "relative",
                top: "-.45rem",
                left: "-.4rem",
                color: colors.uiBorder,
              }}
              className={`fas fa-${props.icon ? props.icon : "ellipsis-h"}`}
            />
          </div>
        )}
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className={"popover"}
        ref={containerEl}
        style={{ ...styles.popoverContainer }}
      >
        <div ref={arrowEl} id="arrow" style={styles.popoverArrow} />
        {props.header && (
          <div style={styles.header}>
            <Items data={[props.header]} />
          </div>
        )}
        <div
          style={styles.list}
          ref={scrollEl}
          onScroll={() => setSelectedItem(NO_SELECTION)}
        >
          <Items
            parentEl={scrollEl}
            data={props.items}
            selectedItem={selectedItem}
            onSelectItem={(id) => {
              setSelectedItem(id);
            }}
            onCloseMenu={() => (containerEl.current.style.display = "none")}
          />
        </div>
      </div>
    </WatchForOutsideClick>
  );
};

const Items = (props) => {
  let items = [];
  if (!props.data) return null;
  props.data.forEach((item, idx) => {
    const iconClass = `fas fa-${item.icon}`;
    let id;
    let timer;
    const onBegin = (originalEvent) => {
      originalEvent.stopPropagation();
      originalEvent.preventDefault();
      id = parseInt(originalEvent.currentTarget.dataset.id, 10);
      let scrollTopAtStart = props.parentEl?.current.scrollTop;
      clearTimeout(timer);
      timer = setTimeout(
        () => {
          if (scrollTopAtStart === props.parentEl?.current.scrollTop)
            if (props.onSelectItem) props.onSelectItem(id);
        },
        originalEvent.type === "mousedown" ? 0 : 100
      );
    };
    const onEnd = (e) => {
      e.stopPropagation();
      e.preventDefault();
      clearTimeout(timer);
      if (props.selectedItem !== NO_SELECTION) {
        if (props.onSelectItem) props.onSelectItem(NO_SELECTION);
        if (item.action) item.action(e);
        if (props.onCloseMenu) props.onCloseMenu(e);
      }
    };

    if (item.name === "divider") {
      items.push(
        <div key={idx} style={styles.hruleContainer}>
          <hr style={styles.hrule} />
        </div>
      );
    } else {
      items.push(
        <div
          className={"itemRow"}
          key={idx}
          style={
            props.selectedItem === idx
              ? {
                  ...styles.menuItem,
                  color: colors.white,
                  backgroundColor: colors.secondary,
                }
              : styles.menuItem
          }
          data-id={idx}
          onTouchEnd={onEnd}
          onTouchCancel={onEnd}
          onMouseDown={(e) => onBegin(e)}
          onTouchStart={(e) => onBegin(e)}
          onMouseUp={onEnd}
        >
          <div>
            <div style={styles.itemName}>{item.name}</div>
            <div style={styles.itemDescription}>{item.desc}</div>
          </div>
        </div>
      );
    }
  });
  return items;
};

const styles = {
  popoverContainer: {
    color: colors.smokeBlack,
    position: "absolute",
    zIndex: 200,
    fontFamily: colors.fontFamily,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: colors.white,
    width: "30rem",
    padding: "0",
    borderRadius: "0.3rem",
    boxShadow: colors.shadow,
    maxHeight: "30rem",
    transition: "opacity .1s linear",
    fontWeight: "900",
    fontSize: "1.5rem",
  },
  button: {
    position: "relative",
    height: ".1rem",
    width: ".1rem",
    fontSize: "1rem",
    lineHeight: "1rem",
    textAlign: "center",
    margin: "1rem",
  },
  header: {
    backgroundColor: colors.uiBorder,
    overflow: "hidden",
    height: "3.3rem",
    borderBottom: "1px solid white",
    borderRadius: "0.3rem 0.3rem 0 0",
  },
  list: {
    overflow: "scroll",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: ".5rem 0 .5rem 0",
    height: menuItemHeight,
    fontSize: menuItemHeight,
    lineHeight: menuItemHeight,
    transition: "background-color .25s linear",
  },
  rowIcon: {
    width: iconContainerSize,
    minWidth: iconContainerSize,
    textAlign: "center",
    borderRadius: iconContainerSize,
    height: iconContainerSize,
    border: "1px solid darkgrey",
    overflow: "hidden",
    margin: "0 0 0 1rem",
  },
  faIcon: {
    fontSize: iconSize,
    lineHeight: iconContainerSize,
    color: colors.primary,
  },
  itemName: {
    flexGrow: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: "1rem",
  },
  itemDescription: {
    fontSize: ".5rem",
    flexGrow: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: "1rem",
  },
  hruleContainer: { height: ".1rem" },
  hrule: { margin: 0, padding: 0, borderColor: colors.uiBorder },
};

export default PopoverMenu;

PopoverMenu.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false,
};

PopoverMenu.propTypes = {
  children: PropTypes.any,
  forceLayout: PropTypes.any,
  header: PropTypes.any,
  icon: PropTypes.any,
  items: PropTypes.array.isRequired,
  layout: PropTypes.any,
  resetToTop: PropTypes.bool,
  scrollTop: PropTypes.number,
  showArrow: PropTypes.bool,
};
