const colors = {
  fontFamily: "brandon-grotesque, sans-serif",
  link: "#2EA3F2",
  primary: "#54818c61",
  secondary: "#4ab253",
  text: "#54818c",
  uiBackground: "#eeeeee",
  uiBorder: "#cccccc",
  uiNavbar: "#000000a6",
  white: "#ffffff",
  black: "#000000",
  shadow: "3px 4px 10px #222222",
  modalBackground: "#00000022",
  tabBackground: "rgb(191, 207, 212)",
  tabs: {
    container: {
      position: "absolute",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "70vw",
      maxWidth: "60rem",
      zIndex: 10,
    },
    padding: "1rem 0 0 0",
    tab: {
      minWidth: "10rem",
      border: 0,
      textAlign: "center",
      borderRadius: 0,
      opacity: 1.0,
      color: "white",
      borderRight: "1px solid #54818c61",
      backgroundColor: "#bfcfd4",
      borderBottom: "0",
      height: "2rem",
      lineHeight: "2rem",
    },
    tabSelected: {
      opacity: 1.0,
      color: "black",
      border: 0,
      borderBottom: "0",
      backgroundColor: "#03DAC6",
    },
    tabFill: {
      borderBottom: "0",
      backgroundColor: "#bfcfd4",
      height: "3rem",
      flexGrow: 1,
    },
  },
};
export default colors;
