import React, { useState, useEffect } from "react";
import Content from "components/Content.js";
import PopoverMenu from "components/PopoverMenu.js";
import colors from "colors";
import Presentation from "components/Presentation";
import { mainMenu } from "assets/content/menus.js";
const mapSrc = "https://parkway.toasterlab.com";

function Home() {
  const [content, setContent] = useState("welcome");
  const [presentation, setPresentation] = useState("");

  useEffect(() => {
    mainMenu.forEach((item) => {
      console.log(item);
      item.name = item.display;
      item.action = () => setContent(item.file);
    });
    console.log(mainMenu);
  }, []);

  const onPoiSelect = (e) => {
    if (e.origin !== mapSrc) return;
    setPresentation(e.data);
  };
  useEffect(() => {
    window.addEventListener("message", onPoiSelect, false);
    return () => {
      window.removeEventListener("message", onPoiSelect, false);
    };
  }, []);

  return (
    <div className="Home">
      {presentation && (
        <Presentation
          {...presentation}
          onOutsideClick={() => setPresentation(null)}
        />
      )}
      <div
        style={{
          background: "#222222", //"-webkit-linear-gradient(270deg,#222222,transparent)",
          fontSize: "1rem",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100vw",
          height: "7rem",
          textAlign: "left",
          display: "flex",

          alignItems: "center",
        }}
      >
        <div>
          <a href="https://toasterlab.com/">
            <img
              style={{ height: "100px", width: "auto", margin: "0 1rem" }}
              alt="Toaserlab logo"
              src="https://assets.toasterlab.com/d237b53c-17b7-43e5-b9fc-2f7912a9ae48/4a5cb851-6c1e-4e4a-b7f3-8c08fdf39926.png"
            />
          </a>
        </div>
        <div style={styles.projectTitle}>Parkway Forest</div>
        <div>
          <PopoverMenu
            showArrow={false}
            resetToTop={true}
            forceLayout={11}
            items={mainMenu}
          >
            <div style={styles.burgerContainer}>
              <div style={styles.line} />
              <div style={styles.line} />
              <div style={styles.line} />
            </div>
          </PopoverMenu>
        </div>
      </div>
      {content && (
        <Content src={content} onOutsideClick={() => setContent(null)} />
      )}
      <iframe
        style={{ height: "100vh", marginTop: "5rem" }}
        title="map"
        width="100%"
        frameBorder="0"
        src={mapSrc}
      />
    </div>
  );
}

const styles = {
  burgerContainer: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    width: "5rem",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    height: "6.7rem",
  },
  line: {
    backgroundColor: colors.white,
    width: "100%",
    height: ".5rem",
    margin: ".5rem 2rem .5rem 0",
    borderRadius: "0.3rem",
  },
  projectTitle: {
    fontFamily: "Amatic SC",
    position: "relative",
    flexGrow: 1,
    margin: "0 0 0 0",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 900,
    color: colors.white,
  },
};

export default Home;
