import CONST from "constants.js";

export const makeAssetURL = (url) => {
  if (!url || url.includes("http")) {
    return url;
  } else {
    return `https://assets.toasterlab.com/d237b53c-17b7-43e5-b9fc-2f7912a9ae48/${url}`;
  }
};

export const detectEnvironment = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) return CONST.PLATFORM.ANDROID;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return CONST.PLATFORM.IOS;
  return CONST.PLATFORM.STANDARD;
};
