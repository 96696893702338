export const mainMenu = [
  {
    display: "Welcome",
    file: "welcome",
  },
  {
    display: "About",
    file: "about",
  },
];
